const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "2017 - 2021",
    title: "Bachelor's Degree",
    desc: "Completed in PSG Insititue of Technology & Applied Research with 8.6 GPA",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "2013 - 2017",
    title: "Higher Secondary",
    desc: "Completed in Malco Vidyalaya Matriculation Higher secondary school with 97.2%",
  },
  {
    id: 3,
    category: "education",
    icon: "icon-graduation",
    year: "2013-2015",
    title: "Secondary School",
    desc: "Completed in Malco Vidyalaya Matriculation Higher secondary school with 98.2%",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "2023 - present",
    title: "Product Engineer",
    desc: "Funds Authorisation Microservice going Serverless in various stacks such as Kubernetes, AWS and Azure",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "2022 - 2022",
    title: "Associate Product Engineer",
    desc: "Enabled Funds Authorisation Microservice's Standalone Capabilities to work without the support of Transact/T24 ",
  },
  {
    id: 6,
    category: "experience",
    icon: "icon-briefcase",
    year: "2021 - 2022",
    title: "Software Engineer",
    desc: "Worked on Funds Authorisation Micro service's Limited Service Mode solution that enables the bank to provide services to their customers while Transact/T24 is not fully available.",
  },
];

export default Data